@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

*{
  scroll-behavior: smooth;
}

/* Hide the spin buttons in WebKit browsers */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide spin buttons in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.navbar {
  transition: transform 0.3s ease-in-out;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.navbar.visible {
  transform: translateY(0);
}


#sd-container {
  /* position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateY(-50%); */
}

.arrow {
  position: relative;
  width: 42px;
  height: 16px;
  opacity: 0;
}

.arrow::before,
.arrow::after {
  content: "";
  width: 21px;
  height: 2px;
  background-color: #003366;
  border-radius: 2px;
  display: inline-block;
}

.arrow::before {
  transform: rotate(45deg) translateX(25%);
}

.arrow::after {
  transform: rotate(-45deg) translateX(-25%);
}

.arrow:nth-child(1) {
  top: -50px;
  opacity: 1;
  animation: arrow2 1s ease 0s infinite;
}

.arrow:nth-child(2) {
  top: -25px;
  animation: arrow1 1s ease 0.25s infinite;
}

@keyframes arrow1 {
  from {
    opacity: 0;
    top: -25px;
  }

  to {
    opacity: 0;
    top: 0px;
  }

  50% {
    opacity: 1;
  }
}

@keyframes arrow2 {
  from {
    opacity: 0;
    top: -35px;
  }

  to {
    opacity: 0;
    top: -10px;
  }

  50% {
    opacity: 1;
  }
}
